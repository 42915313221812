import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const GAHeader: FC<any> = ({
    somthing: any
}) => {


    return (
        <>
        <div className="header"><a href="/">Grand Architect</a></div>
        <div className="header2">NATURAL INTELLIGENCE matter</div>
        </>
    );
};

export default GAHeader;
