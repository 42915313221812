import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const GACircle: FC<any> = ({
    mode,
    chart
}) => {

    const renderCircleChart = () => {
        const blocks = []; 
        

        for (let i = 0; i < 200; i++) {
            const style = {
                transform: `rotate(${1.3 * i}deg)`,
                animationDelay: `${i / 100}s`
            };

            blocks.push(<div className="block" style={style} key={i}></div>);
        }

        return (
            <div className="rating">
                <h2><span className="counter" data-target="90">90</span></h2>
                {blocks}
            </div>
        );
    }

    return (
        <div style={{ position: 'relative' }} >
        <div className={`box ${mode == "toGray" ? "boxgray" : mode=="toColour" ? "boxcolour": ""}`}></div>
        <div className="panicometer">
            <div className="card">
                { chart != undefined ? renderCircleChart() : ""}
            </div>
        </div>
    </div>
    );
};

export default GACircle;
