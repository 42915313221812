// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --clr-neon: rgb(255, 0, 0);
}

.card
{
  position: relative;
  width: 400px;
  height: 400px;
}

.card .rating
{
  position: relative;
  width: 100%;
  height: 100%;
  
}

.card .rating .block
{
  position: absolute;
  width: 2px;
  height: 5px;
  background: lime;
  left: 50%;
  transform-origin: 50% 200px;
  opacity: 0;
  animation: anim 3s linear forwards;
  /* transform: rotate(25deg); */
}

@keyframes anim
{
  0%
  {
    opacity: 0;
    box-shadow: none;
  }
  100%
  {
    opacity: 1;
    box-shadow: 0 0 10px lime,
    0 0 20px #2bff00,
    0 0 40px #2bff00,
    0 0 80px #2bff00,
    0 0 120px #2bff00;
  }
}

.card .rating h2
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  /* transform: rotate(25deg); */
}`, "",{"version":3,"sources":["webpack://./src/assets/css/style2.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;;AAEA;;EAEE,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf;;AAEA;;EAEE,kBAAkB;EAClB,WAAW;EACX,YAAY;;AAEd;;AAEA;;EAEE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,gBAAgB;EAChB,SAAS;EACT,2BAA2B;EAC3B,UAAU;EACV,kCAAkC;EAClC,8BAA8B;AAChC;;AAEA;;EAEE;;IAEE,UAAU;IACV,gBAAgB;EAClB;EACA;;IAEE,UAAU;IACV;;;;qBAIiB;EACnB;AACF;;AAEA;;EAEE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,kBAAkB;EAClB,8BAA8B;AAChC","sourcesContent":[":root {\r\n  --clr-neon: rgb(255, 0, 0);\r\n}\r\n\r\n.card\r\n{\r\n  position: relative;\r\n  width: 400px;\r\n  height: 400px;\r\n}\r\n\r\n.card .rating\r\n{\r\n  position: relative;\r\n  width: 100%;\r\n  height: 100%;\r\n  \r\n}\r\n\r\n.card .rating .block\r\n{\r\n  position: absolute;\r\n  width: 2px;\r\n  height: 5px;\r\n  background: lime;\r\n  left: 50%;\r\n  transform-origin: 50% 200px;\r\n  opacity: 0;\r\n  animation: anim 3s linear forwards;\r\n  /* transform: rotate(25deg); */\r\n}\r\n\r\n@keyframes anim\r\n{\r\n  0%\r\n  {\r\n    opacity: 0;\r\n    box-shadow: none;\r\n  }\r\n  100%\r\n  {\r\n    opacity: 1;\r\n    box-shadow: 0 0 10px lime,\r\n    0 0 20px #2bff00,\r\n    0 0 40px #2bff00,\r\n    0 0 80px #2bff00,\r\n    0 0 120px #2bff00;\r\n  }\r\n}\r\n\r\n.card .rating h2\r\n{\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  text-align: center;\r\n  /* transform: rotate(25deg); */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
