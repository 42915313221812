import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CanvasBackground from "./CanvasBackground";

const GANavBar: FC<any> = ({
    somthing: any
}) => {


    return (
        <div className="navbar">
             <Link className="navbarbutton" to="/">Home</Link>
             <Link className="navbarbutton" to="/aipanic">AI Panic</Link>
             <Link className="navbarbutton" to="/aipanic">GA Says</Link>
         {/* <CanvasBackground></CanvasBackground>  */}
        </div>
    );
};

export default GANavBar;
