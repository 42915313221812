import GAAIPanicPage from 'components/pages/GAAIPanicPage';
import GAHomePage from 'components/pages/GAHomePage';
import React, { useEffect, useState } from 'react';
import "./assets/css/style.css"
import "./assets/css/style2.css"

import { BrowserRouter, Routes, Route, Router } from "react-router-dom";
import GANavBar from 'components/GANavBar';
import GASaysPage from 'components/pages/GASaysPage';

function App() {
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetch('http://localhost:5000')
      .then(response => response.text())
      .then(message => setMessage(message));
  }, []); 


  return (
    <div className="App">
      
      <BrowserRouter>
      <GANavBar></GANavBar>
        <Routes>
          <Route path="/" element={<GAHomePage />} />
          <Route path="/aipanic" element={<GAAIPanicPage />} />
          <Route path="/says" element={<GASaysPage />} />
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;


