import React, { FC, useEffect, useState } from "react";
import CanvasBackground from "../CanvasBackground";
import GANavBar from "../GANavBar";
import GAHeader from "components/GAHeader";
import GACircle from "components/GACircle";

const GAHomePage: FC<any> = ({
    somthing: any
}) => {

    return (
        <>
           <GAHeader></GAHeader>
           <GACircle mode="toColour"></GACircle>

            <p>How do you feel about AI today?</p>

            <div className="container mx-auto p-5">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 text-center">
                    <div className="p-4 rounded shadow"><a href="/aipanic" className="neonbtn">Chill</a></div>
                    <div className="p-4 rounded shadow"><a href="/aipanic" className="neonbtn">No Idea</a></div>
                    <div className="p-4 rounded shadow"><a href="/aipanic" className="neonbtn">Scared</a></div>

                </div></div>

            <div className="container mx-auto p-5">

                <div className="max-w-4xl mx-auto bg-white rounded-lg shadow p-6">
                    <h1 className="text-2xl font-bold text-gray-800 mb-6">Human Genetic Programming for Survival</h1>
                    <p className="text-gray-600 mb-4">Humans, like all living organisms, have evolved traits that enhance their ability to survive and reproduce. These traits manifest through various biological and psychological characteristics:</p>

                    <h2 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Biological Instincts</h2>
                    <p className="text-gray-600 mb-3">Fundamental survival instincts include the drive to seek food and water, the fight-or-flight response in dangerous situations, and the urge to find shelter. These instincts are deeply ingrained and vital for survival.</p>

                    <h2 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Reproductive Drive</h2>
                    <p className="text-gray-600 mb-3">Humans have a strong biological drive to reproduce, ensuring the continuation of the species. This drive includes physical reproduction and complex social behaviors for attracting and choosing suitable mates.</p>

                    <h2 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Social Instincts</h2>
                    <p className="text-gray-600 mb-3">Humans are inherently social beings. Living in groups offers evolutionary advantages such as protection from predators, shared resources, and collaborative efforts in hunting and gathering. Social structures also facilitate cultural and knowledge transmission, crucial for survival across generations.</p>

                    <h2 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Adaptation Mechanisms</h2>
                    <p className="text-gray-600 mb-3">Humans can adapt to a wide range of environments, inhabiting nearly every part of the globe. This adaptability is supported by genetic variability and cultural practices, including making clothing, building shelters, and developing diverse dietary habits.</p>

                    <h2 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Learning and Problem-Solving Capabilities</h2>
                    <p className="text-gray-600">The human brain has evolved to be particularly effective at learning from experience and solving complex problems. This ability allows humans to understand and manipulate their environment to avoid dangers and exploit new resources.</p>
                </div>

            </div>

        </>
    );
};

export default GAHomePage;
